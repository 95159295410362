import React, { HTMLProps } from "react";
import styled from "styled-components";

import { Label } from ".";

interface StyledTextAreaProps {
  isOnDark: boolean;
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  width: 100%;
  min-height: 48px;
  padding: 1rem;
  border: none;
  font: inherit;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  background: ${({ theme }) => theme.colors.black};
  resize: none;

  ${({ theme, isOnDark }) =>
    isOnDark &&
    `border-bottom: 4px solid ${theme.colors.white};
  `}

  ${({ isOnDark }) =>
    isOnDark &&
    `
    padding: 1rem 0;
  `}

  background: ${(props) =>
    props.isOnDark
      ? ({ theme }) => theme.colors.black
      : ({ theme }) => theme.colors.white};

  color: ${(props) =>
    props.isOnDark
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.black};

  ::placeholder {
    color: ${(props) =>
      props.isOnDark
        ? ({ theme }) => theme.colors.grey
        : ({ theme }) => theme.colors.black};
  }
`;

interface TextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  isOnDark?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  name,
  autoComplete,
  label,
  value,
  isOnDark = false,
  rows,
  placeholder,
  className,
  onChange,
}) =>
  label ? (
    <Label htmlFor={id}>
      {label}
      <StyledTextArea
        id={id}
        isOnDark={isOnDark}
        className={className}
        defaultValue={value}
        name={name}
        rows={rows}
        onChange={onChange}
        autoComplete={autoComplete}
        placeholder={placeholder}
      />
    </Label>
  ) : (
    <StyledTextArea
      id={id}
      isOnDark={isOnDark}
      className={className}
      defaultValue={value}
      name={name}
      rows={rows}
      onChange={onChange}
      autoComplete={autoComplete}
      placeholder={placeholder}
    />
  );

export default TextArea;
