import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { H3 } from "../Text";

import getReadablePrice from "../../utils/getReadablePrice";
import { Product } from "../../models";
import getCurrentLocale from "../../utils/getCurrentLocale";
import { useAuth } from "../../hooks/useAuth";

const ProductContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const ProductImageContainer = styled.div`
  border: 4px solid ${({ theme }) => theme.colors.grey};
`;

const ProductDetailContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`;

interface ProductCardProps {
  className?: string;
  productCategorySlug: string;
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({
  className,
  productCategorySlug,
  product,
}) => {
  const { isVerified } = useAuth();
  const { slug: productSlug, name, price, coverImage } = product;

  const isEnglish = getCurrentLocale() === "en";

  const slug = productCategorySlug
    ? `${productCategorySlug}/${productSlug}`
    : productSlug;

  const gatsbyImageData = getImage(coverImage.localFile);

  return (
    <Link to={isEnglish ? `/en/${slug}/` : `/${slug}/`}>
      <ProductContainer className={className}>
        <ProductImageContainer>
          {gatsbyImageData && (
            <GatsbyImage image={gatsbyImageData} alt={name} />
          )}
        </ProductImageContainer>
        <ProductDetailContainer>
          <H3>{name}</H3>
          {isVerified && <p>{getReadablePrice(price)}</p>}
        </ProductDetailContainer>
      </ProductContainer>
    </Link>
  );
};

export default ProductCard;
