import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { GridContainer } from "./Containers";
import { H2 } from "./Text";
import { Product } from "../models";
import SearchInput from "./SearchInput";
import ProductCard from "./Product/ProductCard";

import getCurrentLocale from "../utils/getCurrentLocale";

const SearchContainer = styled.div`
  grid-column: 1 / span 12;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 1 / span 4;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 4rem;
  }
`;

const TitleContainer = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 4rem;
  }
`;

const ProductsContainer = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    gap: 4rem;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

interface ProductOverviewProps {
  className?: string;
  title: string;
  productCategorySlug: string;
  products: Product[];
}

const ProductOverview: React.FC<ProductOverviewProps> = ({
  title,
  productCategorySlug,
  products,
  className,
}) => {
  const [productSearchQuery, setProductSearchQuery] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
  const isEnglish = getCurrentLocale() === "en";

  useEffect(() => {
    const filtered = products.filter(({ name }) =>
      name.toLowerCase().includes(productSearchQuery.toLowerCase())
    );

    setFilteredProducts(filtered);
  }, [productSearchQuery]);

  return (
    <GridContainer className={className}>
      <SearchContainer>
        <SearchInput
          placeholder={
            isEnglish ? `Search for a product` : `Zoek naar een product`
          }
          onChange={(event) => setProductSearchQuery(event.currentTarget.value)}
        />
      </SearchContainer>
      <TitleContainer>
        {title && <H2>{title}</H2>}
        <p>
          {`${filteredProducts.length} ${isEnglish ? "products" : "producten"}`}
        </p>
      </TitleContainer>
      <ProductsContainer>
        {filteredProducts &&
          filteredProducts.map((product) => {
            return (
              <ProductCard
                key={product.id}
                productCategorySlug={productCategorySlug}
                product={product}
              />
            );
          })}
      </ProductsContainer>
    </GridContainer>
  );
};

export default ProductOverview;
