import React, { HTMLProps } from "react";
import styled from "styled-components";

const StyledLabel = styled.label<{ disabled?: boolean }>`
  display: grid;
  font: inherit;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.75px;
  color: ${({ theme }) => theme.colors.black};
  gap: ${({ disabled }) => (disabled ? 0 : "1rem 0")};
`;

interface LabelProps extends HTMLProps<HTMLLabelElement> {
  disabled?: boolean;
}

const Label: React.FC<LabelProps> = ({
  className,
  htmlFor,
  disabled,
  children,
}) => (
  <StyledLabel className={className} htmlFor={htmlFor} disabled={disabled}>
    {children}
  </StyledLabel>
);

export default Label;
