import React, { HTMLProps } from "react";
import styled from "styled-components";
import Input from "./Form/Input";

const StyledInput = styled(Input)`
  background: ${({ theme }) => theme.colors.lightGrey};

  ::placeholder {
    opacity: 0.4;
  }
`;

const SearchInput: React.FC<HTMLProps<HTMLInputElement>> = ({
  placeholder = "Zoek naar een product",
  onChange,
}) => (
  <StyledInput
    id={"search-input"}
    name="searchInput"
    onChange={onChange}
    placeholder={placeholder}
  />
);

export default SearchInput;
