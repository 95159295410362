import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { H1 } from "../Text";

const ProductCategoryHeroContainer = styled.div`
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  gap: 2rem 0;
`;

const Title = styled(H1)`
  grid-column: 1 / span 12;
`;

const ProductCategoryDetailContainer = styled(ReactMarkdown)`
  grid-column: 1 / span 12;
  display: grid;
  gap: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: 1 / span 8;
  }
`;

interface ProductCategoryHeroProps {
  className?: string;
  description?: string;
  title: string;
}

const ProductCategoryHero: React.FC<ProductCategoryHeroProps> = ({
  className,
  title,
  description,
}) => (
  <ProductCategoryHeroContainer className={className}>
    {title && <Title>{title}</Title>}
    {description && (
      <ProductCategoryDetailContainer>
        {description}
      </ProductCategoryDetailContainer>
    )}
  </ProductCategoryHeroContainer>
);

export default ProductCategoryHero;
