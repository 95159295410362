import React, { HTMLProps } from "react";
import styled from "styled-components";

import { Label } from ".";

interface StyledInputProps {
  isOnDark: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  min-height: 48px;
  padding: 0 1rem;
  border: none;
  font: inherit;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  background: ${({ theme }) => theme.colors.black};

  ${({ theme, isOnDark }) =>
    isOnDark &&
    `border-bottom: 4px solid ${theme.colors.white};
  `}

  ${({ isOnDark }) =>
    isOnDark &&
    `
    padding: 1rem 0;
  `}

  background: ${(props) =>
    props.isOnDark
      ? ({ theme }) => theme.colors.black
      : ({ theme }) => theme.colors.white};

  color: ${(props) =>
    props.isOnDark
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.black};

  ::placeholder {
    color: ${(props) =>
      props.isOnDark
        ? ({ theme }) => theme.colors.grey
        : ({ theme }) => theme.colors.black};
  }

  :disabled {
    background: ${({ theme }) => theme.colors.lightGrey};
    padding: 0;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) =>
      props.isOnDark
        ? ({ theme }) => theme.colors.grey
        : ({ theme }) => theme.colors.black};

    box-shadow: 0 0 0px 1000px
      ${({ isOnDark }) =>
        isOnDark
          ? ({ theme }) => theme.colors.black
          : ({ theme }) => theme.colors.white}
      inset !important;

    -webkit-box-shadow: 0 0 0px 1000px
      ${({ isOnDark }) =>
        isOnDark
          ? ({ theme }) => theme.colors.black
          : ({ theme }) => theme.colors.white}
      inset !important;
  }
`;

interface InputProps extends HTMLProps<HTMLInputElement> {
  isOnDark?: boolean;
}

const Input: React.FC<InputProps> = ({
  id,
  name,
  autoComplete,
  label,
  value,
  isOnDark = false,
  type = "text",
  placeholder,
  className,
  onChange,
  disabled,
}) =>
  label ? (
    <Label htmlFor={id} disabled={disabled}>
      {label}
      <StyledInput
        id={id}
        isOnDark={isOnDark}
        className={className}
        onChange={onChange}
        defaultValue={value}
        type={type}
        name={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Label>
  ) : (
    <StyledInput
      id={id}
      isOnDark={isOnDark}
      className={className}
      onChange={onChange}
      defaultValue={value}
      type={type}
      name={name}
      autoComplete={autoComplete}
      placeholder={placeholder}
      disabled={disabled}
    />
  );

export default Input;
